<template>
  <div>
    <div class="mb-1">
      <router-link :to="{ name: 'WarehouseParties' }">
        Назад
      </router-link>
    </div>
    <div class="container card p-2">
      <h1>{{ $t("Перемещение товаров") }}</h1>
      <validation-observer
        ref="form"
        #default="{ invalid }"
      >
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="checkFrom"
        >
          <div class="row">
            <div class="col-3">
              <b-form-group
                :label="$t('С какого склада')"
                label-for="from_warehouse_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="from_warehouse_id"
                  rules="required"
                >
                  <b-form-select
                    id="from_warehouse_id"
                    v-model="form.from_warehouse_id"
                    :state="errors.length > 0 ? false : null"
                    name="from_warehouse_id"
                    value-field="id"
                    text-field="name_uz"
                    :disabled="!!form.products.length"
                    :placeholder="$t('С какого склада')"
                    :options="warehouses.data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-3">
              <b-form-group
                :label="$t('На какой склад')"
                label-for="to_warehouse"
              >
                <validation-provider
                  #default="{ errors }"
                  name="to_warehouse"
                  rules="required"
                >
                  <b-form-select
                    id="to_warehouse"
                    v-model="form.to_warehouse_id"
                    :state="errors.length > 0 ? false : null"
                    name="name"
                    value-field="id"
                    text-field="name_uz"
                    :placeholder="$t('На какой склад')"
                    :options="filteredWarehouses"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                :label="$t('Комментарий')"
                label-for="comment"
              >
                <b-form-textarea
                  id="comment"
                  v-model="form.comment"
                  class="form-control"
                  placeholder="Комментарий"
                  rows="3"
                />
              </b-form-group>
            </div>
          </div>
          <!--  -->
          <div>
            <b-form-group
              :label="$t('Код товара')"
              label-for="barcode"
              class="col-6"
            >
              <validation-provider
                v-if="!form.products.length"
                #default="{ errors }"
                name="barcode"
                rules="required"
              >
                <b-overlay :show="barcodeInputLoading">
                  <b-form-input
                    id="barcode"
                    ref="barcode"
                    v-model="product_barcode"
                    :disabled="!form.from_warehouse_id"
                    :state="errors.length > 0 ? false : null"
                    name="barcode"
                    placeholder="000000000000"
                    @input="
                      getProductByBarcode(product_barcode)
                    "
                  />
                </b-overlay>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-overlay
                v-else
                :show="barcodeInputLoading"
              >
                <b-form-input
                  id="barcode"
                  ref="barcode"
                  v-model="product_barcode"
                  :disabled="!form.from_warehouse_id"
                  name="barcode"
                  placeholder="000000000000"
                  @input="
                    getProductByBarcode(product_barcode)
                  "
                />
              </b-overlay>
            </b-form-group>
          </div>
          <div v-if="form.products.length">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Nomi</th>
                  <th>Soni</th>
                  <th>Barkod</th>
                  <th>Narxi</th>
                  <th>Jami</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <template v-for="(product, index) in form.products">
                  <tr
                    v-if="product.id"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td
                      v-if="product.id"
                      class="text-primary"
                      @click="editProduct(index)"
                    >
                      {{ product.name }}
                    </td>
                    <td>
                      <!-- {{ product.quantity }} dona -->
                      <div class="d-flex">
                        <b-button
                          size="sm"
                          variant="outline-secondary mr-1"
                          @click="decreaseCount(index)"
                        > - </b-button>

                        <b-form-input
                          id="pincode"
                          v-model="product.quantity"
                          type="number"
                          trim
                          disabled
                        />

                        <b-button
                          size="sm"
                          variant="outline-secondary ml-1"
                          @click="increaseCount(index)"
                        > + </b-button>
                      </div>
                      <!-- <b-form-input
                        v-model="product.quantity"
                        name="barcode"
                        placeholder="1"
                        type="name"
                      /> -->
                    </td>
                    <td>{{ product.barcode }}</td>
                    <td v-if="product.id">
                      {{ product.price | formatPrice }}so'm
                    </td>
                    <td v-if="product.id">
                      {{ (product.quantity * product.price) | formatPrice }}so'm
                    </td>
                    <td>
                      <feather-icon
                        color="#FF0000"
                        icon="TrashIcon"
                        @click="deleteProduct(index)"
                      />
                    </td>
                  </tr>
                </template>
                <tr>
                  <th colspan="2">
                    Jami
                  </th>
                  <th colspan="5">
                    {{ getTotalProduct() | formatPrice }} so'm
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-right mt-3">
            <b-button
              variant="primary"
              class="btn-tour-skip"
              @click="save"
            >
              <span class="mr-25 align-middle">Сохранить</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import 'vue-tree-halower/dist/halower-tree.min.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      openModal: false,
      show_add_product: false,
      barcodeInputLoading: false,
      product_barcode: null,
      form: {
        id: null,
        from_warehouse_id: null,
        to_warehouse_id: null,
        comment: null,
        products: [],
      },
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      loading: false,
      form_loading: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouse/GET_ITEMS',
      warehouseProducts: 'productCategory/GET_WAREHOUSE_PRODUCTS',
    }),
    filteredWarehouses() {
      if (
        this.warehouses
        && this.warehouses.data
        && this.warehouses.data.length
      ) {
        return this.warehouses.data.filter(
          el => el.id !== this.form.from_warehouse_id,
        )
      }
      return this.warehouses.data
    },
  },
  watch: {},
  mounted() {
    this.getWarehousAction(this.getAll)
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      this.clearProductsArray()
      if (valid) {
        this.form_loading = true
        this.storeItem(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.form_loading = false
            this.goTo('WarehouseTransferProduct')
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
            this.form_loading = false
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    checkFrom() {
      console.log(555)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    setProduct(data) {
      if (this.form.products.length) {
        this.form.products.push(data)
      } else {
        this.form.products = [data]
      }
      this.clearFormFields()
    },
    updateProduct(data) {
      if (data.index !== null) {
        this.form.products[data.index] = data
      }
      this.clearFormFields()
    },
    decreaseCount(index) {
      if (this.form.products[index].quantity > 1) {
        this.form.products[index].quantity -= 1
      }
    },
    increaseCount(index) {
      this.getWarehouseProducts({
        barcode: this.form.products[index].barcode,
        warehouse_id: this.form.from_warehouse_id,
        current_quantity: this.form.products[index].quantity,
      }).then(res => {
        if (res.success && res.data) {
          this.form.products[index].quantity += 1
        }
      })
    },
    addProduct() {
      this.form.products.push({
        id: null,
        category_id: null,
        price: null,
        quantity: 1,
        name: '',
        barcode: '',
      })
    },
    clearFormFields() {
      this.form.credit_months = null
      this.form.credit_amount = null
      this.form.in_advance_payment_amount = null
    },
    deleteProduct(index) {
      if (this.form.products.length == 1 && index === 0) {
        this.clearProductsArray()
        this.form.products = []
      } else {
        this.form.products.splice(index, 1)
      }
    },
    getTotalProduct() {
      let total = 0
      this.form.products.forEach(el => {
        total += el.quantity * el.price
      })
      return total
    },
    getProductByBarcode(barcode) {
      if (barcode && barcode.length === 12) {
        this.barcodeInputLoading = true
        const foundProduct = this.form.products.find(
          el => el.barcode == barcode,
        )
        let current_quantity = 0
        if (foundProduct) {
          current_quantity = foundProduct.quantity
        }
        this.getWarehouseProducts({
          barcode,
          warehouse_id: this.form.from_warehouse_id,
          current_quantity,
        })
          .then(res => {
            const hasProduct = this.form.products.find(
              el => el.id === res.data.id,
            )
            const hasProductIndex = hasProduct
              ? this.form.products.indexOf(hasProduct)
              : null
            if (hasProduct) {
              this.form.products[hasProductIndex].quantity += 1
            } else {
              this.form.products.push({
                id: res.data.id,
                category_id: res.data.category_id,
                name: res.data.category.name_uz,
                barcode: res.data.barcode,
                price: res.data.price ? res.data.price : res.data.cost,
                quantity: 1,
              })
            }
            this.product_barcode = null
            this.$refs.barcode.focus()
          })
          .finally(() => {
            this.barcodeInputLoading = false
          })
      } else if (barcode && barcode.length > 12) {
        this.product_barcode = Number(String(barcode).slice(0, 12))
      }
    },
    clearProductsArray() {
      if (this.form.products.length > 1) {
        this.form.products = this.form.products.filter(el => el.id != null)
      }
    },
    ...mapActions({
      getWarehousAction: 'warehouse/index',
      getWarehouseProducts: 'productCategory/warehouseProductsByBarcode',
      storeItem: 'warehouse/transferProductsStore',
    }),
  },
}
</script>

<style scoped></style>
